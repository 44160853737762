import { gsap } from 'gsap';


// === ACCORDIONS ===
export function initAccordion() {
    const accordionItems = gsap.utils.toArray(".accordion-item");
    const accordionHeaders = gsap.utils.toArray(".accordion-header");
    const accordionAnimations = [];

    accordionItems.forEach(accordionItem => createAnimation(accordionItem));
    accordionHeaders.forEach(accordionHeader => {
        accordionHeader.addEventListener("click", () => toggleAnimation(accordionHeader));
    });

    function toggleAnimation(accordionHeader) {
        const isInsideTechnicalInformations = accordionHeader.closest('.technical-informations');
        const selectedAnimation = accordionHeader.animation;
        const isReversed = selectedAnimation.reversed();

        if (isInsideTechnicalInformations) {
            selectedAnimation.reversed(!isReversed);
        } else {
            accordionHeaders.forEach(header => {
                header.classList.remove("active");
                if (header.animation !== selectedAnimation) {
                    header.animation.reverse();
                }
            });
            selectedAnimation.reversed(!isReversed);
        }
        accordionHeader.classList.toggle("active", !selectedAnimation.reversed());
    }


    function createAnimation(accordion) {
        const accordionHeader = accordion.querySelector(".accordion-header");
        const accordionContent  = accordion.querySelector(".accordion-content");

        gsap.set(accordionContent, { height: "auto"})
        const tween = gsap.from(accordionContent, {
            height: 0,
            duration: 0.5,
            ease: "power1.inOut",
            reversed: true
        });
        accordionHeader.animation = tween;
        accordionAnimations.push(tween);

    }
}

// === MARQUEE ===
export function initMarquee() {
    const marqueeWrapper = document.querySelector(".certifications-wrapper");
    const marqueeTrack = document.querySelector(".certifications-track");
    const marqueeItems = Array.from(document.querySelectorAll(".certification-item"));

    if (!marqueeWrapper || !marqueeTrack || marqueeItems.length === 0) return;

    const wrapperWidth = marqueeWrapper.offsetWidth;
    let totalWidth = marqueeTrack.scrollWidth;

    const maxClones = 3;
    let clones = 0;

    while (totalWidth < wrapperWidth * 2 && clones < maxClones) {
        marqueeTrack.innerHTML += marqueeTrack.innerHTML;
        totalWidth = marqueeTrack.scrollWidth;
        clones++;
    }

    const marqueeWidth = totalWidth / 2;

    const marqueeAnimation = gsap.to(marqueeTrack, {
        x: -marqueeWidth,
        duration: 30,
        ease: "linear",
        repeat: -1,
    });

    marqueeWrapper.addEventListener("mouseenter", () => marqueeAnimation.pause());
    marqueeWrapper.addEventListener("mouseleave", () => marqueeAnimation.play());
}








export default class Animations {
    constructor() {
        this.init();
    }

    init() {
        initAccordion();
        initMarquee();
    }
}