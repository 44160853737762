import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default class Nav {
    constructor() {
        this.headerHeight = 0;
        this.offsetAdditional = 100;
        this.init();
    }

    init() {
        const updateMainMarginTop = () => {
            const header = document.querySelector('header');
            const main = document.querySelector('main');

            if (header && main) {
                this.headerHeight = header.offsetHeight;
                console.log("Hauteur de l'en-tête mise à jour :", this.headerHeight);

                main.style.marginTop = `${this.headerHeight}px`;
            }
        };

        window.addEventListener('load', updateMainMarginTop);
        window.addEventListener('resize', updateMainMarginTop);

        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth > 1024) {
            const linkWithChildrens = document.querySelectorAll('.navigation-top__top-left > .menu-item.menu-item-has-children');
            if (linkWithChildrens) {
                const siblings = (elem) => {
                    const nodes = [...elem.parentNode.children];
                    return nodes.filter(node => node !== elem);
                };

                const toggleClass = (elem, cls) => {
                    elem.classList.toggle(cls);
                    siblings(elem).forEach(node => {
                        node.classList.remove(cls);
                    });
                };

                linkWithChildrens.forEach(el => {
                    el.addEventListener('click', function () {
                        toggleClass(this, 'bg-red');
                    });
                });
            }
        }

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => {
            link.addEventListener('click', (e) => {
                e.preventDefault();

                const targetId = link.getAttribute('href').slice(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                    gsap.to(window, {
                        duration: 1,
                        scrollTo: {
                            y: targetElement,
                            offsetY: (this.headerHeight || 0) + this.offsetAdditional
                        },
                        ease: "power2.out"
                    });

                    if (history.pushState) {
                        history.pushState(null, "", window.location.pathname);
                    }
                }
            });
        });


        const hash = window.location.hash;
        if (hash) {
            const targetId = hash.slice(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                window.addEventListener('load', () => {
                    gsap.to(window, {
                        duration: 1,
                        scrollTo: {
                            y: targetElement,
                            offsetY: (this.headerHeight || 0) + this.offsetAdditional
                        },
                        ease: "power2.out"
                    });

                    if (history.pushState) {
                        history.pushState(null, "", window.location.pathname);
                    }
                });
            }
        }
    }
}
