import Plyr from "plyr";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Video {
  constructor() {
    this.init();
  }

  init() {
    const controls = `
<div class="plyr__controls">
    <button type="button" class="plyr__control" data-plyr="restart">
        <svg role="presentation"><use xlink:href="#plyr-restart"></use></svg>
        <span class="plyr__tooltip" role="tooltip">Restart</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="rewind">
        <svg role="presentation"><use xlink:href="#plyr-rewind"></use></svg>
        <span class="plyr__tooltip" role="tooltip">Rewind {seektime} secs</span>
    </button>
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="fast-forward">
        <svg role="presentation"><use xlink:href="#plyr-fast-forward"></use></svg>
        <span class="plyr__tooltip" role="tooltip">Forward {seektime} secs</span>
    </button>
    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>
    <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
    <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
    </button>
    <div class="plyr__volume">
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
    </div>
    <button type="button" class="plyr__control" data-plyr="captions">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-captions-on"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-captions-off"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Disable captions</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Enable captions</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="fullscreen">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Exit fullscreen</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Enter fullscreen</span>
    </button>
</div>
`;

    // Setup the player
    const players = [];
    // const player = new Plyr("#player", { controls });
    const jsPlayer = document.querySelectorAll(".js-player");
    if (jsPlayer.length > 0) {
      Array.from(jsPlayer).forEach((p) => {
        let video = new Plyr(p, {
          controls: [
            "play-large", // The large play button in the center
            "rewind", // Rewind by the seek time (default 10 seconds)
            "play", // Play/pause playback
            "fast-forward", // Fast forward by the seek time (default 10 seconds)
            "progress", // The progress bar and scrubber for playback and buffering
            "current-time", // The current time of playback
            "duration", // The full duration of the media
            // "mute", // Toggle mute
            "volume", // Volume control
            "captions", // Toggle captions
            "settings", // Settings menu
            "fullscreen", // Toggle fullscreen
          ],
          muted: true,
          autoplay: false,
          youtube: {
            noCookie: true,
            modestbranding: true,
          },
          vimeo: {
            byline: false,
            title: false,
          },
        });
        video.poster = p.getAttribute("data-plyr-poster");

        players.push({ player: video, element: p });
      });
    }
    this.initScrollTrigger(players);
  }

  initScrollTrigger(players) {
    const autoplaySections = document.querySelectorAll(".autoplay");

    function pauseOtherVideos(currentPlayer, allPlayers) {
      allPlayers.forEach(({ player }) => {
        if (player !== currentPlayer && !player.paused) {
          player.pause();
        }
      });
    }

    autoplaySections.forEach((section) => {
      const videoElement = section.querySelector(".plyr");

      if (videoElement) {
        const match = players.find((p) => p.element === videoElement);

        if (match && match.player) {

          ScrollTrigger.create({
            trigger: section,
            start: "top+=20% 80%",
            end: "bottom center",
            onEnter: () => {
              try {
                pauseOtherVideos(match.player, players);
                match.player.muted = true;
                match.player.volume = 0;
                match.player.play();
              } catch (error) {
                console.error("Autoplay failed:", error);
              }
            },
            onLeave: () => {
              match.player.pause();
            },
            onEnterBack: () => {
              match.player.play();
            },
            onLeaveBack: () => {
              match.player.pause();
            },
          });
        }
      }
    });
  }
}

let userInteracted = false;
document.addEventListener("mousemove", () => {
  if (!userInteracted) {
    userInteracted = true;
  }
});
document.addEventListener("click", () => {
  if (!userInteracted) {
    userInteracted = true;
  }
});
