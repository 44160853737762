import { S as E, P as S } from "./panorama-carousel/panorama-carousel.js";

const $ = function() {
  const s = document.createElement("link").relList;
  if (s && s.supports && s.supports("modulepreload")) return;
  for (const e of document.querySelectorAll('link[rel="modulepreload"]')) r(e);
  new MutationObserver((e) => {
    for (const t of e)
      if (t.type === "childList")
        for (const n of t.addedNodes)
          n.tagName === "LINK" && n.rel === "modulepreload" && r(n);
  }).observe(document, { childList: !0, subtree: !0 });
  function o(e) {
    const t = {};
    return (
      e.integrity && (t.integrity = e.integrity),
      e.referrerpolicy && (t.referrerPolicy = e.referrerpolicy),
      e.crossorigin === "use-credentials"
        ? (t.credentials = "include")
        : e.crossorigin === "anonymous"
        ? (t.credentials = "omit")
        : (t.credentials = "same-origin"),
      t
    );
  }
  function r(e) {
    if (e.ep) return;
    e.ep = !0;
    const t = o(e);
    fetch(e.href, t);
  }
};
$();
typeof window != "undefined" &&
  window.SwiperElementRegisterParams &&
  window.SwiperElementRegisterParams(["panoramaEffect"]);
function b({ swiper: a, extendParams: s, on: o }) {
  s({ panoramaEffect: { depth: 200, rotate: 30 } }),
    o("beforeInit", () => {
      if (a.params.effect !== "panorama") return;
      a.classNames.push(`${a.params.containerModifierClass}panorama`),
        a.classNames.push(`${a.params.containerModifierClass}3d`);
      const r = { watchSlidesProgress: !0 };
      Object.assign(a.params, r), Object.assign(a.originalParams, r);
    }),
    o("progress", () => {
      if (a.params.effect !== "panorama") return;
      const r = a.slidesSizesGrid,
        { depth: e = 200, rotate: t = 30 } = a.params.panoramaEffect,
        g = (t * Math.PI) / 180 / 2,
        h = 1 / (180 / t);
      for (let i = 0; i < a.slides.length; i += 1) {
        const d = a.slides[i],
          P = d.progress,
          c = r[i],
          y = a.params.centeredSlides ? 0 : (a.params.slidesPerView - 1) * 0.5,
          l = P + y,
          f = 1 - Math.cos(l * h * Math.PI),
          m = `${l * (c / 3) * f}px`,
          p = l * t,
          u = `${((c * 0.5) / Math.sin(g)) * f - e}px`;
        d.style.transform =
          a.params.direction === "horizontal"
            ? `translateX(${m}) translateZ(${u}) rotateY(${p}deg)`
            : `translateY(${m}) translateZ(${u}) rotateX(${-p}deg)`;
      }
    }),
    o("setTransition", (r, e) => {
      a.params.effect === "panorama" &&
        a.slides.forEach((t) => {
          t.style.transitionDuration = `${e}ms`;
        });
    });
}

const carouselPano = document.querySelectorAll(".swiper-panorama-3d .swiper");

if (carouselPano) {
  carouselPano.forEach((carousel) => {
    const buttonPrev = carousel
      .closest(".swiper-panorama-3d")
      .querySelector(".swiper-button-prev");
    const buttonNext = carousel
      .closest(".swiper-panorama-3d")
      .querySelector(".swiper-button-next");

    const slider = new E(carousel, {
      modules: [S, b],
      effect: "panorama",
      slidesPerView: 1.5,
      loop: false,
      loopAdditionalSlides: 1,
      centeredSlides: !0,
      grabCursor: !0,
      panoramaEffect: { depth: 150, rotate: 35 },
      navigation: true,
      breakpoints: {
        480: { slidesPerView: 2, panoramaEffect: { rotate: 35, depth: 150 } },
        640: { slidesPerView: 3, panoramaEffect: { rotate: 30, depth: 150 } },
        1024: { slidesPerView: 4, panoramaEffect: { rotate: 30, depth: 200 } },
        1200: { slidesPerView: 4, panoramaEffect: { rotate: 25, depth: 250 } },
      },
      on: {
        init: function() {
          buttonPrev.classList.add("hidden");
        },
      },
    });

    const nbrSlides = slider.slides.length;

    buttonPrev.onclick = () => {
      slider.slidePrev();
    };

    buttonNext.onclick = () => {
      slider.slideNext();
    };

    slider.on("slideChange", function(event) {
      const activeIndex = event.activeIndex;

      if (activeIndex === 0) {
        buttonPrev.classList.add("hidden");
      } else {
        buttonPrev.classList.remove("hidden");
      }

      if (activeIndex === nbrSlides - 1) {
        buttonNext.classList.add("hidden");
      } else {
        buttonNext.classList.remove("hidden");
      }
    });

    // Lancement d'une vidéo
    const videos = carousel.querySelectorAll(".has-video-js");

    if (videos) {
      videos.forEach((elt) => {
        elt.onclick = (event) => {
          const url = elt.getAttribute("data-videourl");

          const existingpopup = document.getElementById(url);

          if (existingpopup) {
            existingpopup.classList.add("active");
            document.body.classList.add("overflow-hidden");
          } else {
            const popupElt = document.createElement("div");
            popupElt.classList.add("popup-video");
            popupElt.id = url;

            const iframeElt = document.createElement("iframe");
            iframeElt.src = url;
            iframeElt.width = 768;
            iframeElt.height = 432;

            const closeElt = document.createElement("img");
            closeElt.src =
              window.location.origin +
              "/wp-content/themes/orlo/web/src/img/icons/icon-close.svg";
            closeElt.classList.add("close");

            popupElt.appendChild(iframeElt);
            popupElt.appendChild(closeElt);

            document.body.insertAdjacentElement("beforeend", popupElt);

            window.setTimeout(() => {
              popupElt.classList.add("active");
              document.body.classList.add("overflow-hidden");
            }, 100);

            //Close popup
            popupElt.onclick = (e) => {
              if (e.target === popupElt) {
                closePopup();
              }
            };

            closeElt.onclick = (e) => {
              closePopup();
            };

            function closePopup() {
              popupElt.classList.remove("active");
              document.body.classList.remove("overflow-hidden");

              const iframeSrc = iframeElt.src;
              iframeElt.src = iframeSrc;
            }
          }
        };
      });
    }
  });
}
